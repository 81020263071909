// Overall layout of the masthead
.masthead {
  .profile-picture {
    text-align: center;
    margin-left: auto; margin-right: auto;

    img {
      // Responsive image!
      width: 50%; max-width: 160px;
      // half the width (diameter) of the picture makes the border circular
      @include border-radius(50%)
    }
  }

  .blog-title {
    margin-top: 0.5em;
    word-spacing: -0.1em;
    text-transform: uppercase;
    .alternating-items *:nth-child(even) { font-weight: bold; }
    .alternating-items *:nth-child(odd) { font-weight: normal; }
  }

  .blog-subtitle {
    margin-top: 0.5em;
  }

  .blog-title, .blog-subtitle {
    font-family: $masthead-font-family;
    line-height: 1.0;
  }

  #main-nav {
    margin-top: 1em;
  }

  .main-navigation, .contact-and-social {
    list-style-type: none;

    // Ugh. I'd rather say "normal + 100 weight" here.
    li { font-weight: 400; }
  }

  #sub-nav {
    margin-top: 1em;
    padding-bottom: 2rem;
  }
}

.sheet {
  .headline {
    .wbitdd-link {
      background-color: $brand-color;
      color: $text-color-on-branded-background !important;
      padding: 0.3rem 1.5rem 0.3rem 1.5rem;
      border-radius: 1rem;

      &:hover {
        background-color: $text-color-on-branded-background;
        color: $brand-color !important;
      }
    }
  }
}

// Overall layout of the sheet
.sheet {
  padding-top: 0;

  .headline {
    padding: 0;
    text-align: center;
  }

  .each-post,
  #navigate-to-other-posts,
  .colophon {
    @extend %vertical-rhythm;
  }
}
// Layout of a collection of posts
.post-collection .each-post {
  .continue-reading {
    margin-top: 0.5em;
  }
}

// Navigating to other posts


// Layout of a single post summary
article.post-summary {
  // Rules that refer to the container go directly here

  // Don't interfere with the vertical rhythm of the container
  @include avoid-double-spacing-inside-element();

  // Rules that should work outside this container go here:
  @import "parts/article";

  // Don't display footnotes in the summary
  .footnotes, .footnoteRef { display: none; }
}
