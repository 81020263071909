@font-face {
  font-family: 'Sinkin Sans';
  src: url('/font/SinkinSans-100Thin-webfont.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Sinkin Sans';
  src: url('/font/SinkinSans-100ThinItalic-webfont.woff') format('woff');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Sinkin Sans';
  src: url('/font/SinkinSans-200XLight-webfont.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Sinkin Sans';
  src: url('/font/SinkinSans-200XLightItalic-webfont.woff') format('woff');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Sinkin Sans';
  src: url('/font/SinkinSans-300Light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Sinkin Sans';
  src: url('/font/SinkinSans-300LightItalic-webfont.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Sinkin Sans';
  src: url('/font/SinkinSans-400Italic-webfont.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Sinkin Sans';
  src: url('/font/SinkinSans-400Regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Sinkin Sans';
  src: url('/font/SinkinSans-500Medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Sinkin Sans';
  src: url('/font/SinkinSans-500MediumItalic-webfont.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Sinkin Sans';
  src: url('/font/SinkinSans-600SemiBold-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Sinkin Sans';
  src: url('/font/SinkinSans-600SemiBoldItalic-webfont.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Sinkin Sans';
  src: url('/font/SinkinSans-700Bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Sinkin Sans';
  src: url('/font/SinkinSans-700BoldItalic-webfont.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Sinkin Sans';
  src: url('/font/SinkinSans-800Black-webfont.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Sinkin Sans';
  src: url('/font/SinkinSans-800BlackItalic-webfont.woff') format('woff');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Sinkin Sans';
  src: url('/font/SinkinSans-900XBlack-webfont.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Sinkin Sans';
  src: url('/font/SinkinSans-900XBlackItalic-webfont.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

