// I want a consistent paragraph spacing that doesn't depend
// on the size of the paragraph text font.
$space-above-paragraph: 0.8 * $body-font-size;

// Leave space by adding margin-top.
.mid-col-container #content {
  margin-top: 1em;
}

ul.post-list {
  list-style-type: none;
  li {
    margin-top: 0.6 * $space-above-paragraph;
  }
}
