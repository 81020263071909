@font-face {
    font-family: 'M+ 1m';
    src: url('/font/mplus-1m-bold-webfont.woff2') format('woff2'),
         url('/font/mplus-1m-bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'M+ 1m';
    src: url('/font/mplus-1m-light-webfont.woff2') format('woff2'),
         url('/font/mplus-1m-light-webfont.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'M+ 1m';
    src: url('/font/mplus-1m-medium-webfont.woff2') format('woff2'),
         url('/font/mplus-1m-medium-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'M+ 1m';
    src: url('/font/mplus-1m-regular-webfont.woff2') format('woff2'),
         url('/font/mplus-1m-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'M+ 1m';
    src: url('/font/mplus-1m-thin-webfont.woff2') format('woff2'),
         url('/font/mplus-1m-thin-webfont.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}
