// Discourse, to make the comments <iframe> easier to read while upgrading Discourse.
#discourse-embed-frame {
  // The default height (as of 2024-12-20) is 150px.
  // This small box makes it hard to see an HTTP 503 error page,
  // so let's make the frame taller by default.
  min-height: 600px;
}

// codeblock tags

.bogus-wrapper figure.code {
  @extend %codeblockish;

  .highlight {
    padding: 0 !important;
    background-color: transparent !important;

    table {
      border-collapse: collapse;

      td.code {
        pre { padding: 0; }
      }
    }
  }

  * {
    text-align: left;
  }
}

