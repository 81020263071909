// Non-responsive elements

// Font decoration

$normal-hyperlink-color: $brand-color;
a {
  color: $normal-hyperlink-color;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &:visited {
    color: darken($normal-hyperlink-color, 20%);
  }
}

// When a link is inside a highlight box,
// the background color of the highlight box
// washes out the link text.
$hyperlink-inside-highlight-box-color: darken($normal-hyperlink-color, 20%);
.highlight a {
  color: $hyperlink-inside-highlight-box-color;
  &:visited {
    color: darken($hyperlink-inside-highlight-box-color, 20%);
  }
}

.translation {
  font-style: italic;
}

.centered-text-flow-around {
  // We need display: block in order to ensure that we can center this item
  display: block;
  text-align: center;
  margin: auto;

  margin-top: 1em;
  margin-bottom: 1em;
}

// https://www.h3xed.com/web-development/how-to-make-a-responsive-100-width-youtube-iframe-embed
.embedded-video-container {
  position: relative;
  height: 0;
  padding-bottom: 65%; // tweaked visually

  .embedded-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.body-text-block {
  max-width: 90%;
  width: 600px;

  @extend .centered-text-flow-around;
}

.highlight,
.instruction,
.guideline {
  background-color: lighten($brand-color, 20%);
  padding: 1em;
  * {
    @include avoid-double-spacing-inside-element();
  }
}

%codeblockish {
  background-color: lighten($brand-color, 30%);

  overflow: auto;

  @include expand-to-fill(10%, $content-width);
  @include expand-to-fill(15%, $content-width);
  @include expand-to-fill(25%, $content-width);
  @include expand-to-fill(50%, $content-width);
  @include expand-to-fill(100%, $content-width);
}

pre,
.test-table,
.user-displayed-message {
  @extend %codeblockish;
  @extend %responsive-codeblockish;
}

// Layout
// Leave space by adding margin-top.
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 3rem;
}
p,
aside,
blockquote,
ol,
ul,
details,
pre,
.test-table,
figure,
.footnotes,
.space-above-paragraph {
  margin-top: $space-above-paragraph;
}
p,
aside,
blockquote,
ol,
ul,
details,
pre,
.footnotes {
  line-height: 1.8;
}
h2 + p,
h3 + p,
h4 + p,
h5 + p,
h6 + p {
  margin-top: 0.5 * $space-above-paragraph;
}
.title {
  line-height: 1;
}
.meta {
  margin-top: 1 * $space-above-paragraph;
  * {
    display: block;
  }
}

ul,
ol {
  list-style-position: initial;
  @include media-query-mobile() {
    margin-left: 1.2rem;
  }

  @include media-query-full() {
    margin-left: 3rem;
  }

  li {
    margin-top: 0.5 * $space-above-paragraph;
    @include avoid-double-spacing-inside-element();
  }

  // When nesting a list, let the vertical space come
  // from the list elements and not from the lists.
  ul,
  ol {
    margin-top: 0 !important;
    > li {
      margin-top: 0.5 * $space-above-paragraph !important;
    }
  }
}

%caption {
  text-align: center;
  margin-left: 10%;
  margin-right: 10%;
}

figure {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  img {
    text-align: center;
    max-width: 100%;
  }
  figcaption {
    @extend %caption;
    @extend %responsive-caption;
  }
}

figure.half-width, .interstitial-visual-element  {
  max-width: 400px;
  width: 50%;
}
figure.smaller {
  max-width: 200px;
  width: 25%;
}
.interstitial-visual-element {
  margin-top: 1em;
  margin-bottom: 1em;
}

img.paragraph-eyecatcher {
  float: right;
  width: 30%;
  margin-left: 1ex;
  margin-bottom: 1ex;
}

%callout {
  color: lighten($body-text-color, 30%);
  border-left: 4px solid $brand-color;

  padding-left: 0.8em;
  padding-right: 0.8em;

  @include media-query-mobile() {
    margin-left: 1.2rem;
  }

  @include media-query-full() {
    margin-left: 3rem;
  }

  > :last-child {
    margin-bottom: inherit !important;
  }
}

blockquote {
  @extend %callout;

  > :nth-child(1):before {
    @extend %open-quote;
  }
}

aside,
.aside,
.explanation {
  @extend %callout;
}

.explanation {
  background-color: white;
}

.test-table > table {
  border-collapse: collapse;
  th,
  td {
    border: 1px solid;
  }
}

/* HACK I don't know why I need this in order footnote text to appear on the same line as the footnote number, even though I don't need to do this on blog.jbrains.ca. Weird. */

.footnotes p {
  display: inline;
}

div.sourceCode::after {
  content: attr(caption);
  padding: 0;
  @extend %caption;

  // Weird hack that probably has something to do
  // with using the ::after pseudoelement.
  // Fortunately, as long as the source code block
  // remains flush left, this will look fine.
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.emphasized-paragraph {
  background-color: lighten($brand-color, 20%);
  font-size: 125%;
  margin: 1em;
  padding: 1em !important;
}

details.tldr {
  background-color: #eeeeee;
  padding: 1em;
  margin: 1rem;

  summary {
    font-weight: bold;
  }

  section.details {
    font-size: 80%;
  }
}

details.external-excerpt {
  border: 1px solid;
  background-color: lavender;
  padding: 0.5em;

  summary {
    font-weight: bold;
  }

  section.details-content {
    // We need explicit vertical rhythm here, because evidently
    // <details> doesn't respect the 'gap' attribute for Flexbox.
    margin-top: 1em;

    // Local reset, because I'm not ready to use flex _everywhere_ here.
    * {
      margin: 0;
      padding: 0;
    }

    display: flex;
    flex-flow: column nowrap;
    gap: 1em;

    blockquote {
      margin-left: 10%;
      padding-left: 0.5em;
    }

    pre {
      padding: 0.5em;
      overflow-x: scroll;
    }

    .note {
      font-size: 80%;
      font-style: italic;
    }
  }
}
