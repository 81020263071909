$brand-color: #e36877;
$text-color-on-branded-background: white;
$main-text-color: black;
$body-text-color: $main-text-color;
$body-background-color: #ffffff;

body {
  background-color: $body-background-color;
  .masthead {
    background-color: darken($body-background-color, 5%);
  }
  color: $body-text-color;
}

$color-main: #258fb8;
$color-gray01: #666;
$color-gray02: #999;
$color-gray03: #ccc;
$color-gray04: #ddd;
$color-gray05: #fff;
