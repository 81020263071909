@mixin square($property){
  width: $property;
  height: $property;
}
@mixin user-select($select){
  -moz-user-select: $select;
  -khtml-user-select: $select;
  -webkit-user-select: $select;
  -o-user-select: $select;
  user-select: $select;
}
@mixin border-shadow($top: $color-gray05, $bottom: $color-gray04){
  border-top: 1px solid $top;
  border-bottom: 1px solid $bottom;
}

@mixin center($width, $height) {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: $width/(-2);
  margin-top: $height/(-2);
  width: $width;
  height: $height;
}
@mixin transition($delay) {
  -webkit-transition: color $delay;
  -moz-transition: color $delay;
  -o-transition: color $delay;
  transition: color $delay;
}
@mixin positioned-left($portion-percentage, $content-width) {
  width: $portion-percentage;
  max-width: calc($portion-percentage / 100.0%) * $content-width;
  // do not set left:, so that this appears at the natural boundary
}

// $portion-to-sidestep-percentage: how much stuff to the left to sidestep (ex. 25%)
@mixin positioned-right($portion-to-sidestep-percentage, $content-width, $additional-spacing-percentage: 5%) {
  $leftover-portion-percentage: 100% - $portion-to-sidestep-percentage;

  width: (1 - calc($additional-spacing-percentage / 100.0%)) * $leftover-portion-percentage;
  max-width: (1 - calc($additional-spacing-percentage / 100.0%)) * calc($leftover-portion-percentage / 100.0%) * $content-width;
  position: absolute; right: 0;
}

@mixin leave-horizontal-space($to-left-edge: 0, $to-right-edge: 0) {
  padding-left: $to-left-edge;
  padding-right: $to-right-edge;
}

@mixin border-radius($radius) {
  border-radius: $radius;
  -moz-border-radius: $radius;
  -webkit-border-radius: $radius;
}
// $stretch-factor-percentage (ex. 10% means 10% to the right)
@mixin expand-to-fill($stretch-factor-percentage, $normal-width) {
  @include media-query-larger-than((1 + calc($stretch-factor-percentage / 100%)) * 1.03 * $content-width) {
    max-width: calc(($stretch-factor-percentage + 100%) / 100%) * $normal-width;
  }
}
@mixin avoid-double-spacing-inside-element() {
  &:first-child { margin-top: 0; }
}
