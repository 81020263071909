@import "utilities";

// Reset layout to nothing
* { margin: 0; padding: 0 }

%vertical-rhythm {
  margin-bottom: 2em;
}

@mixin comfortable-space-from-the-top-of-the-screen() {
  padding-top: 2rem;
}

// Define media queries for responsive design
$mobile-screen-min-width: 320px;
$content-width: 3.5 * $mobile-screen-min-width;
$mobile-device-max-width: 1025px;

// This is a mobile screen
@mixin media-query-mobile() {
  @media screen and (max-width: $mobile-device-max-width) {
    @content;
  }
}

// This is a full-size screen, such as a standard monitor
@mixin media-query-full() {
  @media screen and (min-width: $mobile-device-max-width) {
    @content;
  }
}

// This is a wide page
@mixin media-query-larger-than($width) {
  @media screen and (min-width: $width) {
    @content;
  }
}

// The container for the entire content area
.container {
  max-width: $content-width;

  // Allow background color to stretch to the edges
  @include leave-horizontal-space(0, 0);

  position: relative;
}

// Overall content layout.
.container {
  // Can't put the comfortable space from the top of the screen here,
  // because then the scroll bars look weird.

  @include media-query-mobile() {
    // Top to bottom: masthead, spacer, sheet
    .masthead, .sheet {
      position: static;
      // Don't crowd the edges of the screen
      @include leave-horizontal-space($to-left-edge: 12px, $to-right-edge: 12px);
    }
    .masthead {
      @include comfortable-space-from-the-top-of-the-screen();
      margin-bottom: 2em; // Spacer before the content below
    }
    .masthead .profile-picture { text-align: center; }
  }

  @include media-query-full() {
    // Left to right: masthead, sheet
    $masthead-portion-horizontal-mode: 30%;
    .masthead {
      @include positioned-left($masthead-portion-horizontal-mode, $content-width);
      @include leave-horizontal-space($to-left-edge: 1em, $to-right-edge: 1em);

      // Kept on screen while scrolling the main content area
      left: 0;
      position: fixed;
      z-index: 9999;

      // Force scrolling. Magic!
      // See http://stackoverflow.com/questions/696827/fixed-positioning-with-scrolling#696841 for a discussion about this
      height: 100%;
      overflow: auto;

      // Force the content past the bottom of the page, in order
      // to enable scrolling.
      > *:last-child {
        // 33px = the height of the Hello Bar
        padding-bottom: 2 * 33px;
      }
    }

    .sheet {
      // positioned to the right of the masthead
      @include positioned-right($masthead-portion-horizontal-mode, $content-width, 10%);
      @include leave-horizontal-space($to-right-edge: 0.5em);
    }

    .masthead, .sheet {
      // Comfortable space from the top of the screen here,
      // to avoid the scroll bar looking weird
      @include comfortable-space-from-the-top-of-the-screen();
    }
  }
}

.center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
