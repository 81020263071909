@import "font";
@import "color";

$body-font-size: 1.4rem;
$article-body-font-size: $body-font-size;

// REFACTOR Separate this into responsive body styles and responsive article styles
@mixin responsive-article-styles($article-body-font-size) {
  font-size: $article-body-font-size;

  // CONTRACT
  // expects values for:
  // $article-body-font-size
  // $space-above-paragraph
  // $brand-color
  // $body-text-color

  // Font sizes
  $slightly-smaller-font-size: 80%;

  h1 { font-size: 180%; }
  h2 { font-size: 140%; }
  h3, h4, h5, h6 { font-size: 120%; }
  aside { font-size: $slightly-smaller-font-size; }
  p, blockquote, li { font-size: $article-body-font-size; }
  title { font-size: 200%; }

  .meta {
    font-size: $slightly-smaller-font-size;
  }

  %responsive-codeblockish {
    font-size: $slightly-smaller-font-size;
    padding: 1.0 * $article-body-font-size;
  }

  .test-table > table {
    th, td {
      padding: 0.2 * $article-body-font-size;
    }
  }

  %responsive-caption {
    font-size: $slightly-smaller-font-size;
  }

  .pullquote-right {
    &::before {
      /* Reset metrics. */
      padding: 0;
      border: none;

      /* Content */
      content: attr(data-pullquote);

      /* Pull out to the right, modular scale based margins. */
      float: right;
      width: 40%;
      margin: 0 0 2rem 2rem;
      padding: 0.5em;

      /* Baseline correction */
      position: relative;
      top: 0;

      /* Typography */
      font-family: $headline-font-family;
      font-size: 110%;
      line-height: 1.3;
      background-color: lighten($brand-color, 20%);
    }
  }
}

body {
  @include media-query-mobile() {
    @include responsive-article-styles($article-body-font-size: 0.8 * $body-font-size);
  }
  @include media-query-full() {
    @include responsive-article-styles($article-body-font-size: 1.0 * $body-font-size);
  }

  font-family: $main-font-family;
  font-weight: 300;
  color: $main-text-color;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $headline-font-family;
}

pre, code {
  font-family: $code-font-family;
  font-weight: 400;
}

a {
  text-decoration: none;
  color: $brand-color;
}

// Don't extend the surrounding line height
sub, sup, code { line-height: 0; }

%open-quote {
  content: "\201C";
  font-family: "Times New Roman", serif;
  color: lighten($body-text-color, 80%);
  line-height: 0;
  font-size: 4em;    // Intentionally oversize
  vertical-align: -0.4em;
}
