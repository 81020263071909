// Layout
.post, .share, .comments {
  @extend %vertical-rhythm;
}

// Fonts and color
.comments {
  .title {
    color: $brand-color;
  }
}

article.post {
  @import "parts/article";
}
