@import "mplusfonts";
@import "sinkinsansfonts";

@import url(https://fonts.googleapis.com/css?family=Roboto:200,300,400,500,600,700,400italic,700italic);
@import url(https://fonts.googleapis.com/css?family=Heebo:200,300,400,500,600,700,800,900);
@import url('https://fonts.googleapis.com/css?family=Fira+Code:300,400,500,600,700,800,900');
$main-font-family: "Heebo", "Roboto", "Helvetica", sans-serif;

@import url('https://fonts.googleapis.com/css?family=Playfair+Display');
$headline-font-family: "Playfair Display", "Source Sans Pro", "Helvetica", sans-serif;

@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700,400italic,700italic);
$masthead-font-family: "Source Sans Pro", "Helvetica", sans-serif;

@import url(https://fonts.googleapis.com/css?family=Lekton:400,400italic,700);
$code-font-family: "Fira Code", "M+ 1m", "Lekton", "Monaco", monospace;

$font-icon: FontAwesome;
$font-icon-path: "/font/fontawesome-webfont";

@font-face {
  font-family: 'FontAwesome';
  src: url('#{$font-icon-path}.eot');
  src: url('#{$font-icon-path}.eot?#iefix') format('embedded-opentype'),
  url('#{$font-icon-path}.woff') format('woff'),
  url('#{$font-icon-path}.ttf') format('truetype'),
  url('#{$font-icon-path}.svgz#FontAwesomeRegular') format('svg'),
  url('#{$font-icon-path}.svg#FontAwesomeRegular') format('svg');
  font-weight: normal;
  font-style: normal;
}
